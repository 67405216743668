<template>
  <div>
    <el-row>
      <el-col :span="18" :offset="3">
        <!-- 面包屑导航 -->
        <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(3)">技术服务</span> &gt; <span @click="changeNav(3)">技术支持</span> &gt; <span>知识库</span></p>
      </el-col>
<!--    顶部图标栏-->
      <el-col :span="18" :offset="3">
        <ul class="topIcon">
          <!-- 循环生成分类列表 -->
          <li 
            v-for="(item,index) in topIcon" 
            :key="index" 
            :class="{active:item.type == Current}"
            @click="question(item.title,item.type)"
          >
            <img :src="item.imgSrc"/>
            <p>{{item.title}}</p>
          </li>
        </ul>
      </el-col>
    </el-row>

<!--    搜索框-->
    <el-row style="margin-bottom:3%;">
      <el-col :span="12" :offset="6" class="search_inp">
        <div>
          <el-input placeholder="请输入您要搜索的内容..." v-model="searchInput" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="searchKnow"></el-button>
          </el-input>
        </div>
        <i class="el-icon-user" style="font-size: 32px;color: #255583;margin-left: 1%;cursor: pointer" @click="dialogFormVisible = true"></i>
      </el-col>
    </el-row>

<!--    文章列表-->
    <el-row>
      <el-col :span="18" :offset="3">常见问题</el-col>
      <el-col :span="18" :offset="3">
        <div class="articleBox">
          <div 
            v-for="(item,index) in articleList" 
            :key="index" 
            @click="showKnow(item.id)" 
            class="article">
            <div class="trangle"></div>
            <div class="type">{{item.type}}</div>
            <div class="fl">
              <p>{{item.title}}</p>
              <p>{{item.question}}</p>
            </div>
            <div class="fr">
              <p>{{item.qtime}}</p>
              <div>
                <img src="@/assets/technicalSupport/techSupport/eyes.png" />
                {{item.number ? item.number : 0}}
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="18" :offset="3">
        <div class="pange">
          <el-button size="small" :disabled="pageCurrent == 1" @click="prevPage">上一页</el-button>
          <div class="page"><span>{{pageCurrent}}</span>/{{total}}</div>
          <el-button size="small" :disabled="pageCurrent == total" @click="nextPage">下一页</el-button>
        </div>
      </el-col>
    </el-row>

    <!--手机号弹出框-->
    <el-dialog title="" :visible.sync="dialogFormVisible">
      <el-form  ref="telForm" >
        <el-form-item label="我的手机号" :label-width="formLabelWidth">
          <el-input v-model="tel" clearable placeholder="请输入手机号码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitTel">提 交</el-button>
      </div>
    </el-dialog>

    <!-- 知识库文章弹出框 -->
    <el-dialog title="详情" :visible.sync="knowDialogVisible">
      <div>
        <div class="atra">
          <div class="atraa">
            <h6 class="fl">问题分类</h6>
            <p class="fl tita" style="">{{atricle.type}}</p>
            <div class="clearfix"></div>
          </div>
          <div class="atraa">
            <h6 class="fl">问题标题</h6>
            <p class="fl titb">{{atricle.title}}</p>
            <div class="clearfix"></div>
          </div>
          <div class="atraa">
            <h6 class="fl">问题描述</h6>
            <p class="fl titb">{{atricle.question}}</p>
            <div class="clearfix"></div>
          </div>
          <div class="atraa">
            <h6>问题图片</h6>
            <div class="atrab">
              <div v-if="atricle.listAnswer.length > 0">
                <div class="atrac" v-for="item in atricle.listAnswer" :key="item.id">
                  <img :src="window.ipConfig.baseUrl + item.img" @click="handlepreview(window.ipConfig.baseUrl + item.img)" />
                </div>
                <div class="clearfix"></div>
              </div>
              <div v-else>
                <p class="atrad">无图片</p>
              </div>
            </div>
          </div>
        </div>
        <div class="artb">
          <div class="atraa">
            <h6 class="fl">解决方案</h6>
            <p class="fl titb">{{atricle.answer}}</p>
            <div class="clearfix"></div>
          </div>
          <div class="atraa">
            <h6>图片回复</h6>
            <div class="atrab">
              <div v-if="atricle.list.length > 0">
                <div class="atrac" v-for="item in atricle.list" :key="item.id">
                  <!-- 打包 -->
                  <img :src="window.ipConfig.baseUrl + item.img" @click="handlepreview(window.ipConfig.baseUrl + item.img)" />
                </div>
              </div>
              <div v-else>
                <p class="atrad">无图片</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 图片预览dialog -->
    <el-dialog :visible.sync="picPrevViewDialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { getKnowList,getListByType,searchKnowList,knowDeatil,telKnowList } from '@/api';
export default {
  name: "techSupport",
  data(){
    return{
      // 知识库文章弹出框
      knowDialogVisible: false,
      // 页面page参数
      // 当前页码
      pageCurrent:1,
      // 每页文章数量
      pageTotal:5,
      // 总共页码
      total:0,
      // 当前分类
      Current:'',
      // 输入手机号
      tel:'',
      // 弹窗显示
      dialogFormVisible: false,
      // 表单label宽度
      formLabelWidth: '120px',
      // 顶部分类数据
      topIcon:[
          {imgSrc:require('@/assets/technicalSupport/techSupport/jiemian.png'),title:'显示界面',type:'11'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/lianjie.png'),title:'桌面连接',type:'33'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/kadun.png'),title:'卡顿',type:'44'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/waishe.png'),title:'外设',type:'55'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/wangluo.png'),title:'网络',type:'66'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/zhongduan.png'),title:'云终端',type:'77'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/ruanjian.png'),title:'软件',type:'88'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/quanxian.png'),title:'权限',type:'99'},
          // {imgSrc:require('@/assets/technicalSupport/techSupport/kuaijiejian.png'),title:'快捷键'},
          {imgSrc:require('@/assets/technicalSupport/techSupport/qita.png'),title:'其他',type:'22'}
      ],
      // 输入框数据
      searchInput:'',
      // 文章列表数量
      articleNum:3,
      // 文章列表
      articleList:[],
      // 文章详情数据
      atricle:{
        listAnswer:[],
        list:[]
      },
      // 图片预览框
      picPrevViewDialogVisible:false,
      // 弹窗图片地址
      dialogImageUrl:'',
      // 弹窗显示
      dialogVisible: false
    }
  },
  computed:{
    window: function () {
      return window
    }
  },
  methods:{
    // 图片预览函数（赋值展示弹窗）
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片预览函数（赋值展示弹窗）
    handlepreview(url){
      this.dialogImageUrl = url
      this.picPrevViewDialogVisible = true
    },
    // 导航切换
    changeNav(navNum){
      this.$store.dispatch('changeShow',navNum)
    },
    // 详细展示某条文章
    async showKnow(id){
      const result = await knowDeatil(id)
      this.knowDialogVisible = true;
      this.atricle = result.data[0]
    },
    // 获取文章列表
    async getKnowList(){
      const result = await getKnowList(this.pageCurrent,this.pageTotal)
      this.articleList = result.data
      // 分页器页数整理
      this.total = result.data[0] ? Math.ceil(result.data[0].total / this.pageTotal) : 1
    },
    // 点击下一页
    async nextPage(){
      this.pageCurrent += 1
      // 按分类查询
      if(this.Current != ''){
        // 清空搜索框
        this.searchInput = ''
        for (const key in this.topIcon) {
          if(this.topIcon[key].type == this.Current){
            const result = await getListByType(this.topIcon[key].title,this.pageCurrent,this.pageTotal)
            this.articleList = result.data
            this.total = result.data[0] ? Math.ceil(result.data[0].total / this.pageTotal) : 1
          }
        }
      }else if(this.tel != ''){
        // 是否在查询个人问题
        this.commitTel()
      }else if(this.searchInput != ''){
        // 是否在搜索状态
        this.searchKnow()
      }else{
        // 普通获取数据
        this.getKnowList()
      }
      
    },
    // 点击上一页
    async prevPage(){
      this.pageCurrent -= 1
      if(this.Current != ''){
        // 判断状态整理页码
        for (const key in this.topIcon) {
          if(this.topIcon[key].type == this.Current){
            const result = await getListByType(this.topIcon[key].title,this.pageCurrent,this.pageTotal)
            this.articleList = result.data
            this.total = result.data[0] ? Math.ceil(result.data[0].total / this.pageTotal) : 1
          }
        }
      }else if(this.tel != ''){
        // 是否在查询个人问题
        this.commitTel()
      }else if(this.searchInput != ''){
        // 是否在搜索状态
        this.searchKnow()
      }else{
        // 普通获取数据
        this.getKnowList()
      }
      
    },
    // 按分类查看问题
    async question(title,type){
      if(type == this.Current){
        // 点击当前激活分类，清空分类信息
        this.Current = 1
        this.getKnowList()
        this.Current = ''
      }else{
        // 点击其他分类，搜索分类信息，清空搜索框与个人电话
        this.searchInput = ''
        this.tel = ''
        // 切换分类激活状态
        this.Current = type
        // 回到第一页
        this.pageCurrent = 1
        const result = await getListByType(title,this.pageCurrent,this.pageTotal)
        this.articleList = result.data
        // 整理页码
        this.total = result.data[0] ? Math.ceil(result.data[0].total / this.pageTotal) : 1
      }
    },
    // 搜索功能
    async searchKnow(){
      // 清空分类与个人电话
      this.Current = ''
      this.tel = ''
      const result = await searchKnowList(this.searchInput,this.pageCurrent,this.pageTotal)
      this.articleList = result.data
      // 整理页码
      this.total = result.data[0] ? Math.ceil(result.data[0].total / this.pageTotal) : 1
    },
    // 手机号查询
    async commitTel(){
      if(this.tel.length == 11){
        // 清空分类与查询
        this.Current = ''
        this.searchInput = ''
        const result = await telKnowList(this.tel,this.pageCurrent,this.pageTotal)
        this.articleList = result.data
        // 整理页码
        this.total = result.data[0] ? Math.ceil(result.data[0].total / this.pageTotal) : 1
        // 关闭弹框
        this.dialogFormVisible = false
      }else{
        this.$message.error('请输入正确的手机号码')
        return false
      }
    }
  },
  created(){
    // 页面加载完成时获取问题列表
    this.getKnowList()
  },
  watch: {
    // 监听并修改文章查看次数
    knowDialogVisible: function (newValue) {
      if(newValue == false){
        this.articleList.filter(item => {
          if(item.id == this.atricle.id){
            item.number += 1
          }
        })
      }
    }
  },
}
</script>

<style scoped>
/* 通用样式*/
li{
  list-style: none;
}
/* 导航栏 */
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
.search_inp > div{
  width: 90%;
  display: inline-block;
}
.search_inp > i{
  display: inline-block;
  position: relative;
  top: 7px;
}
/*页面样式*/
.topIcon{
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 4% auto;
}
.topIcon li{
  text-align: center;
  transition: all 0.3s;
  width: 9%;
  border-radius: 8px;
  cursor: pointer;
}
.topIcon li:hover{
  box-shadow: 0 0 15px #9295e7;
}
.active{
  box-shadow: 0 0 15px #9295e7;
}
.topIcon li img{
  width: 50%;
}
/*搜索框*/
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/*文章列表*/
.articleBox{margin-top: 10px;margin-bottom: 30px;}
.articleBox .article{
  box-shadow: 0 0 2px #d7d5d5;
  margin: 10px auto;
  padding: 0.5% 1% 0.5% 8%;
  cursor: pointer;
  transition: all 0.3s;
}
.article:hover{
  box-shadow: 0 0 10px #b4b4b4;
}
.article .fl{
  width: 60%;
}
.article .fr{
  width: 40%;
  text-align: right;
}
.article .fr div{
  margin-top: 9%;
}
.article .fr div img{
  position: relative;
  top: 3px;
}
.article{
  position: relative;
}
.type{
  position: absolute;
  top: 17px;
  left: -10px;
  width: 70px;
  transform: rotateZ(-45deg);
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.trangle{
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 70px solid #009944;
  border-right: 70px solid transparent;
}
.article .fl p:first-child{
  font-size: 22px;
  font-weight: bold;
  margin-top: 2%;
  padding-bottom: 1%;
}
.article .fl p:last-child{
  font-size: 18px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  padding-bottom: 2%;
}
.pange{
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
}
.page{
  font-size: 16px;
  margin: 0px 10px;
  line-height: 32px;
}
.page span{
  color: #409EFF;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.clearfix{
  clear: both;
}

.atra{
  border-radius: 5px;
  border: 1px solid #ddd;
  padding:3%;
}
.artb{
  border-radius: 5px;
  border: 1px solid #ddd;
  padding:3%;
  margin-top: 3%;
}
.atraa{
  margin-bottom: 2%;
}
.tita{
  display:inline-block;
  padding: 0px 5px;
  background-color: #009944;
  width: auto;
  line-height: 30px;
  color: #fff;

}
.atraa h6{
  font-size: 16px;
  color: #000;
  font-weight: bold;
  width: 16%;

}
.atraa .titb{
  width: 84%;
}
.atraa p{
  font-size: 16px;
}
.atrab{
  min-height: 50px;
  border-radius: 5px;
  padding: 3%;
}
.artb{

}
.atrac{
  width: 25%;
  float: left;
  height: 150px;
  overflow: hidden;
}
.atrac img{
  width: 90%;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
@media screen and (min-width: 1500px){
  .articleBox .article{
    padding: 0.5% 1% 0.5% 4%;
  }
  .atraa h6{
    font-size:18px;
  }
  .topIcon li{
    width: 7%;
  }
}
</style>